import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ResponsiveImageRender from '../../../ResponsiveImageRender';
import { SoldOutBadge } from '../../../../coreComponents/boxes/Badge';
import useStyles from '../styles';

interface SoldOutTileProps {
  productId: string;
  name: string;
  imageUrl: string;
  description: string;
}

const SoldOutTile = (props: SoldOutTileProps): JSX.Element => {
  const classes = useStyles();
  const {
    name, description, productId, imageUrl
  } = props;

  return (
    <Grid className={classes.soldOutCard} key={productId} aria-label={`${name}, sold out`}>
      <div className={classes.imageContainer}>
        <ResponsiveImageRender
          title=""
          testId=""
          className={classes.image}
          mobile={imageUrl || '/images/fallback-product-image.jpeg'}
          desktop={imageUrl || '/images/fallback-product-image.jpeg'}
          altText={description}
          ariaHidden
        />
      </div>
      <Grid className={`${classes.contentContainer} ${classes.soldOutContentContainer}`}>
        <Typography variant="h3" className={classes.title} aria-hidden>{name}</Typography>
        <Typography component="span" className={classes.soldOutBadge}>
          <SoldOutBadge aria-hidden />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SoldOutTile;
