import React from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import fontStyles from '@/common/fontStyles';
import {
  smallMobileStartBreakpoint
} from '@/materialUi/theme';

const useStyle = makeStyles((theme) => createStyles({
  heading: {
    ...fontStyles.h1,
    textTransform: 'capitalize',
    lineHeight: 1.2,
    alignSelf: 'flex-end',
    marginTop: 30,
    marginBottom: 26,
    margin: 0,
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      ...fontStyles.h1Mobile,
      marginTop: 26,
      marginBottom: '8px',
      lineHeight: '36px'
    }
  }
}));

interface CategoryTitleProps {
  title: string;
}

const CategoryTitle = ({ title }: CategoryTitleProps): JSX.Element => {
  const classes = useStyle();
  return (
    <Typography variant="h1" className={classes.heading} role="heading" data-testid="category-heading">
      {title}
    </Typography>
  );
};

export default CategoryTitle;
