import React from 'react';
import Grid from '@material-ui/core/Grid';
import ProductOptionsDropdown from '../ProductOptionsDropdown';
import SelectorButton from '../../../../coreComponents/buttons/SelectorButton';
import useStyles from '../styles';
import { ButtonProps } from '@/coreComponents/buttons/SelectorButton/SelectorButton';
import { DisplayableModifier } from '@/domain/product/types';
import { FormChangeEvent } from '@/common/eventTypes';
import { SelectorButtonState } from '@/hooks/useButtonState';
import { CtaButton } from '@/coreComponents/buttons/CtaButton/CtaButton';

interface ProductDropdownsWithAddToCartProps {
  id: string;
  productNameWithoutSpaces: string;
  hasMultipleSizes: boolean;
  hasAdditionalOptions: boolean;
  formattedUnitPrice: string;
  quantity: number;
  showQuantity?: boolean;
  productName: string;
  sizes: DisplayableModifier[] | undefined;
  selectedSize?: DisplayableModifier;
  updateSize: (
    event: FormChangeEvent,
    child: React.ReactNode
  ) => void;
  additionalOptions: DisplayableModifier[];
  selectedAdditionalOption?: DisplayableModifier;
  updateAdditionalOption: (
    event: FormChangeEvent,
    child: React.ReactNode
  ) => void;
  handleAddToCart: (e: MouseEvent, param: boolean) => void;
  updateQuantity: (
    event: FormChangeEvent,
    child: React.ReactNode
  ) => void;
  buttonState: SelectorButtonState;
  buttonDetails: ButtonProps;
  quantitySelectorInputLabel: string;
  isMyHutBoxProduct?: boolean;
  clickHandler?: () => void;
}

const ProductDropdownsWithAddToCart = (props: ProductDropdownsWithAddToCartProps): JSX.Element => {
  const classes = useStyles();
  const {
    id,
    productName,
    productNameWithoutSpaces,
    hasMultipleSizes,
    hasAdditionalOptions,
    formattedUnitPrice,
    sizes,
    additionalOptions,
    quantity,
    showQuantity = true,
    buttonState,
    updateSize,
    selectedSize,
    selectedAdditionalOption,
    updateAdditionalOption,
    updateQuantity,
    handleAddToCart,
    quantitySelectorInputLabel,
    buttonDetails,
    isMyHutBoxProduct,
    clickHandler
  } = props;

  return (
    <Grid className={classes.ctaContainer}>
      {hasMultipleSizes && (
        <ProductOptionsDropdown
          id={id}
          productName={productName}
          productNameWithoutSpaces={productNameWithoutSpaces}
          formattedUnitPrice={formattedUnitPrice}
          selectedProductOption={selectedSize}
          productOptions={sizes}
          updateProductOption={updateSize}
          label="Sizes"
          type="sizes"
        />
      )}
      {hasAdditionalOptions && (
        <ProductOptionsDropdown
          id={id}
          productName={productName}
          productNameWithoutSpaces={productNameWithoutSpaces}
          formattedUnitPrice={formattedUnitPrice}
          selectedProductOption={selectedAdditionalOption}
          productOptions={additionalOptions}
          updateProductOption={updateAdditionalOption}
          label="Additional Options"
          type="additional-options"
        />
      )}
      <Grid className={classes.sizeDropdownAndCtaButtonWrapper}>
        { isMyHutBoxProduct ? (
          <CtaButton
            color="secondary"
            labelText="Get Started"
            size="medium"
            clickHandler={clickHandler}
          />
        ) : (
          <SelectorButton
            onClick={(e) => handleAddToCart(e as MouseEvent, false)}
            onSelectionChange={updateQuantity}
            quantity={quantity}
            showQuantity={showQuantity}
            inputLabelText={quantitySelectorInputLabel}
            button={buttonDetails}
            select={{
              id: productNameWithoutSpaces,
              name: 'quantity'
            }}
            state={buttonState}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ProductDropdownsWithAddToCart;
